<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="true"
    :onExport="doExport"
  >
    <!--
    el-form是不是设置了 label-with='auto', 而且里面元素是不是有 v-show 切换。
    元素在v-show为false的情况下， 是获取不到宽度的，所以在销毁的时候报这个错。 改成 v-if 就解决了
    -->
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="75px"
      label-position="left"
      size="medium"
      class="filter-form grid"
    >
      <el-form-item label="任务名称：" prop="name">
        <el-input v-model="form.name" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="任务类型：" prop="type">
        <el-select
          v-model="form.type"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in [
              { id: '', name: '全部' },
              { id: 'jielong', name: '接龙' },
            ]"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户范围：" prop="scope">
        <el-select
          v-model="form.scope"
          placeholder="请选择"
        >
          <el-option
            v-for="item in scopeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发布状态：" prop="status">
        <el-select
            v-model="form.status"
            placeholder="请选择"
        >
          <el-option
              v-for="item in [
              { id: -1, name: '全部' },
              { id: 1, name: '已发布' },
              { id: 0, name: '未发布' },
            ]"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <DatePeriodPicker
        v-if="filterForm.deadline_start_time !== undefined"
        label="截止时间："
        start-prop="deadline_start_time"
        :start-time.sync="form.deadline_start_time"
        end-prop="deadline_end_time"
        :end-time.sync="form.deadline_end_time"
        style="grid-column: 1 / span 3"
      />
      <DatePeriodPicker
        v-if="filterForm.post_start_time !== undefined"
        label="发布时间："
        start-prop="post_start_time"
        :start-time.sync="form.post_start_time"
        end-prop="post_end_time"
        :end-time.sync="form.post_end_time"
        style="grid-column: 1 / span 3"
      />
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from '@/base/layout/LayoutFilter'
import DatePeriodPicker from '@/base/components/Form/DatePeriodPicker'
import {exportTaskList} from '../api'
export default {
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  model: {
    prop: 'filterForm',
    event: 'updateFilter',
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      //用户范围为：所有会员，指定职务。按社团云现有组件直接用，图中只是展示而已
      scopeList: [
        {
          id: -1,
          name: '全部',
        },
        {
          id: 0,
          name: '所有会员',
        },
        {
          id: 1,
          name: '指定职务',
        },
      ],
      rangeList: [
        // {
        //   id: 0,
        //   name: '全部',
        // },
        {
          id: 1,
          name: '1-10',
        },
        {
          id: 2,
          name: '10-20',
        },
        {
          id: 3,
          name: '20-50',
        },
        {
          id: 4,
          name: '50-100',
        },
        {
          id: 5,
          name: '100以上',
        },
      ],
    }
  },
  computed: {
    form: {
      get() {
        return this.filterForm
      },
      set(val) {
        this.$emit('updateFilter', val)
      },
    },
    countRange: {
      get() {
        return this.getFormRange('activities_count')
      },
      set(val) {
        this.setFormRange('activities_count', val)
      },
    },
  },
  methods: {
    setFormRange(key, rangeIndex) {
      if (key === 'activities_count') {
        switch (rangeIndex) {
          case 1:
            {
              this.form.min_activity = 0
              this.form.max_activity = 10
            }
            break
          case 2:
            {
              this.form.min_activity = 10
              this.form.max_activity = 20
            }
            break
          case 3:
            {
              this.form.min_activity = 20
              this.form.max_activity = 50
            }
            break
          case 4:
            {
              this.form.min_activity = 50
              this.form.max_activity = 100
            }
            break
          case 5:
            {
              this.form.min_activity = 100
              this.form.max_activity = -1
            }
            break
          default: {
            this.form.min_activity = -1
            this.form.max_activity = -1
          }
        }
      }
    },
    getFormRange(key) {
      if (key === 'activities_count') {
        let countRange
        const { min_activity: min, max_activity: max } = this.form
        if (min === -1 && max === -1) {
          countRange = ''
        } else if (max === 10) {
          countRange = 1
        } else if (max === 20) {
          countRange = 2
        } else if (max === 50) {
          countRange = 3
        } else if (max === 100) {
          countRange = 4
        } else if (min === 100) {
          countRange = 5
        }
        return countRange
      }
      return -1
    },
    //重置表单
    resetForm() {
      this.$refs['logFilterForm'].resetFields()
      this.uploadFilter(this.form)
    },
    //筛选
    ok() {
      this.uploadFilter(this.form)
    },
    doExport(q) {
      return exportTaskList({...this.form, ...q})
    }
  },
}
</script>

<style lang="scss" scoped>
.filter-form.grid {
  grid-template-columns: repeat(3, minmax(auto, 320px));
}
</style>
