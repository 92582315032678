import api from '@/base/utils/request'

export const saveTask = (data) => {
  return api({url:'/admin/task/task/save', method: 'post', data})
}

export const getTaskDetail = (data) => {
  return api({url:'/admin/task/task/detail', method: 'post', data})
}

export const releaseTask = (data) => {
  return api({url:'/admin/task/task/publish', method: 'post', data})
}

export const pushTask = (data) => {
  return api({url:'/admin/task/task/push', method: 'post', data})
}