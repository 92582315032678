<template>
  <div class="page">
    <list-layout
      ref="taskList"
      :filter-form="form"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getList"
      :on-delete="onDelete"
      :on-put-back="recover"
      @command="onCommand"
    >
      <template #top>
        <div class="top-btn">
          <el-button class="" size="small" type="primary" @click="handleAdd()"
            >新增</el-button
          >
        </div>
      </template>
      <template #filter>
        <task-list-list-filter
          :filter-form="form"
          :upload-filter="refreshList"
        ></task-list-list-filter>
      </template>

      <template #tabletop>
        <div class="list-btn">
          <el-button size="small" type="danger" @click="onListDelete">删除</el-button>
          <el-button v-if="pushing && form.tab !== 'deleted'" class="" size="small" type="danger" @click="pushing = false"
          >中断当前任务</el-button>
        </div>
      </template>
    </list-layout>

    <el-dialog
      width="600px"
      class="dialog-vertical"
      :visible.sync="isDetail"
      title="任务详情"
      close-on-press-escape
      close-on-click-modal
    >
      <task-detail :form-data="detailData" readonly></task-detail>
    </el-dialog>

    <el-dialog
      width="600px"
      class="dialog-vertical"
      :visible.sync="isComplete"
      title="完成情况"
      close-on-press-escape
      close-on-click-modal
    >
      <list-layout
        v-if="isComplete"
        :thead="completeHead"
        :filter-form="completeQuery"
        :on-fetch="getRecords"
      ></list-layout>
    </el-dialog>
  </div>
</template>

<script>
import ListLayout from '../../../base/layout/ListLayout'
import {
  cancelTask,
  deleteTaskForever,
  deleteTaskTemporary,
  fetchTaskList,
  fetchTaskRecords,
  recoverTask,
  softDelete,
} from '../api'
import TaskListListFilter from '../components/TaskListListFilter'
import TaskDetail from '../components/TaskDetail'
import { pushTask, releaseTask } from '../api/detail'
export default {
  components: { TaskDetail, TaskListListFilter, ListLayout },
  data() {
    return {
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      form: {
        page: 1,
        page_size: 20,
        name: '',
        type: '', // 类型（英文，可为空串）
        scope: -1, // 范围
        deadline_start_time: -1,
        deadline_end_time: -1,
        post_start_time: -1,
        post_end_time: -1,
        status: -1, // 发布状态
        export_ids: [],
        tab: 'normal',
      },
      /**
       *           {
                "create_time": "2024-05-22 14:42:35",
                "status": 1,
                "type": "jielong",
                "content": "天台呢接龙，额阿斯顿发",
                "images": [],
                "files": [],
                "finish_stats": "0\/0\/0"
            },
       */
      thead: [
        {
          type: 'selection',
        },
        {
          label: '任务名称',
          prop: 'name',
          minWidth: 200,
        },
        {
          label: '任务类型',
          prop: 'type_text',
          minWidth: 100,
        },
        {
          label: '用户范围',
          prop: 'scope_text',
          minWidth: 100,
        },
        {
          label: '需完成人数/完成人数/ 未完成人数',
          prop: 'finish_stats',
          minWidth: 260,
        },
        {
          label: '截止时间',
          prop: 'deadline_time',
          minWidth: 200,
        },
        {
          label: '发布状态',
          prop: 'status_text',
          minWidth: 100,
        },
        {
          label: '发布时间',
          prop: 'post_time',
          minWidth: 200,
        },
        {
          label: '消息通知',
          prop: 'msgs_text',
          minWidth: 300,
        },
        {
          label: '操作',
          align: 'right',
          type: 'operation',
          width: 380,
          getWidth: (form) => (form.tab === 'deleted' ? 260 : 370),
          operations: [
            {
              command: 'edit',
              visible: (row, form) =>
                row.status !== 1 && form.tab !== 'deleted', // 非已发布
            },
            {
              /**
               * 已发布的状态编辑后将重新发布，同时需要出个弹窗提醒用户，“已发布的任务重新编辑需将已完成的用户清除，请谨慎操作”
               *
               * “已发布的任务重新编辑需将已完成的用户清除，请谨慎操作”的弹窗
               * 出现条件为：修改了用户范围，完成条件，任务类型。如只修改内容，截止时间，名称，则无须清空已完成的用户
               */
              command: 'resend',
              text: '编辑重发',
              visible: (row, form) =>
                row.status === 1 && form.tab !== 'deleted', // 已发布
            },
            {
              command: 'detail',
              text: '任务详情',
              // visible: (row, form) => form.tab !== 'deleted'
            },
            {
              command: 'progress',
              text: '完成情况',
              // visible: (row, form) => form.tab !== 'deleted'
            },
            {
              command: 'release',
              text: '发布',
              loading: (row, form) => {
                return row.id === this.releaseId && this.releaseLoading
              },
              visible: (row, form) =>
                row.status !== 1 && form.tab !== 'deleted', // 非已发布
            },
            {
              command: 'down',
              text: '下架',
              visible: (row, form) =>
                row.status === 1 && form.tab !== 'deleted', // 已发布
            },
            {
              command: 'send',
              text: '通知', //点击“通知”则出现弹窗：“每天仅可通知一次，通知范围为未完成的会员”
              visible: (row, form) => form.tab !== 'deleted',
              disabled: row => row.status !== 1
            },
            {
              command: 'putback',
              visible: (row, form) => form.tab === 'deleted',
            },
            {
              command: 'delete',
            },
          ],
        },
      ],

      isDetail: false,
      detailData: {},

      isComplete: false,
      completeQuery: {
        id: '',
        page: 1,
        page_size: 30,
      },
      completeHead: [
        {
          prop: 'avatar',
          label: '头像',
          width: 80
        },
        {
          prop: 'nickname',
          label: '用户名',
          minWidth: 150
        },
        {
          prop: 'phone',
          label: '手机号',
          minWidth: 150
        },
        {
          prop: 'title_name',
          label: '会内职务',
          minWidth: 180
        },
      ],

      pushing: false,

      releaseLoading: false,
      releaseId: ''
    }
  },
  methods: {
    handleAdd() {
      this.$router.push({
        name: 'TaskAdd',
      })
    },
    onCommand({ command, row }) {
      console.log(command, row)
      switch (command) {
        case 'edit':
        case 'resend':
          this.$router.push({
            name: 'TaskEdit',
            params: {
              id: row.id,
            },
          })
          break
        case 'detail':
          this.detailData = row
          this.isDetail = true
          break
        case 'down':
          cancelTask({
            id: row.id,
          })
            .then(({ data, msg }) => {
              this.$message.success(msg)
              this.refreshList()
            })
            .catch(() => {})
          break
        case 'release':
          this.releaseLoading = true
          this.releaseId = row.id;
          releaseTask({ id: row.id })
            .then(({ code, data, msg }) => {
              /**
             * {
               "notification_enable"： 0, // 是否需要发起推送
               "users_count": 0, // 待发送用户，为 0 也不要发起推送
              }
             */
              const { notification_enable, users_count } = data
              if (notification_enable && users_count > 0) {
                this.pushing = true
                this.doPush(row.id)
              } else {
                this.releaseLoading = false

                if (code) {
                  this.$message.success(msg)
                }
              }
              this.refreshList() 
            })
            .catch(() => {
              this.releaseLoading = false
            })
          break
        case 'send': {
          this.pushing = true;
          this.doPush(row.id)
        }
          break
        case 'progress':
          this.completeQuery.id = row.id
          this.completeQuery.page = 1
          this.isComplete = true
          break
        default:
      }
    },
    //单个删除
    onDelete(idList, forever = false) {
      return forever
        ? deleteTaskForever({ ids: idList })
        : deleteTaskTemporary({ ids: idList })
    },
    onListDelete() {
      this.$refs.taskList.deleteSelected()
    },
    getList(q) {
      return fetchTaskList(q)
    },
    refreshList(q = {}) {
      this.$refs.taskList.getList({
        page: 1,
        ...q,
      })
    },
    getRecords(q) {
      return fetchTaskRecords(q)
    },
    recover(idList) {
      return recoverTask({ ids: idList })
    },
    doPush(taskId = '') {
      if (this.pushing === false) return
      pushTask({ id: taskId })
        .then(({ code, data, msg }) => {
          const {
            total_count, // 总需发送数量
            left_count, // 待发送数量，不为 0 时，前端循环调用，可中断
          } = data

          if (left_count > 0) {
            this.doPush(taskId)
          } else {
            this.pushing = false
          }

          if (code) {
            // this.$message.success(msg)
            this.$notify({
              title: msg,
              message: `总需发送数量：${total_count}<br/>待发送数量：${left_count}`,
              position: 'bottom-right',
              dangerouslyUseHTMLString: true,
            })
          }

          // this.refreshList()
        })
        .catch(() => {})
          .finally(() => {
            this.releaseLoading = false;
          })
    },
  },
}
</script>
