<template>
  <el-form ref="formRef" :model="formData" class="medium-form" size="medium" label-width="42px">
    <el-form-item label="详情：">
      <el-input v-if="!readonly"
          v-model="form.content"
          type="textarea"
          :rows="10"
          placeholder="请输入"
          autosize
          style="height: unset; min-height: unset; max-width: 290px"
      ></el-input>
      <p v-else style="white-space: pre-wrap;">{{form.content}}</p>
    </el-form-item>
    <el-form-item label="图片：">
      <media-wall
          v-model="form.images"
          :width="150"
          :height="96"
          :borderRadius="4"
          :limit="9 - form.images.length"
          addText="上传图片"
          :UploadType="['image']"
          :disabled="readonly"
          :show-add="!readonly"
      ></media-wall>
      <p v-if="readonly && form.images.length === 0">{{'无'}}</p>
    </el-form-item>
    <el-form-item label="文档：" style="max-width: 440px">
      <el-upload v-if="!readonly"
          action="#"
          :before-remove="beforeRemove"
          :on-remove="handleRemove"
          :http-request="handleUpload"
          :before-upload="beforeUpload"
          multiple
          :file-list="form.files"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
          只能上传word、excel、ppt、pdf文件，且不超过150M
        </div>
      </el-upload>
      <p v-else-if="form.files.length">
        <a :href="file.url" target="_blank" v-for="file in form.files" :key="file.url">{{file.name || file.title || file.url}}</a>
      </p>
      <p v-else>{{'无'}}</p>
    </el-form-item>
  </el-form>
</template>

<script>
import fileUpload from '../mixin/fileUpload'
import MediaWall from '../../common/components/MediaWall'

export default {
  components: {MediaWall},
  mixins: [fileUpload],
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    readonly: Boolean
  },
  data() {
    return {

    }
  },
  computed: {
    form: {
      get() {
        return this.formData;
      },
      set(val) {
        this.$set('update:formData', val)
      }
    }
  },
}
</script>