import api from '@/base/utils/request'
import fetchFile from '@/base/utils/download'

export const fetchTaskList = (data) => {
  return api({url:'/admin/task/task/index', method: 'post', data})
}

/**
 * 下架任务
 * @param data
 */
export const cancelTask = (data) => {
  return api({url: '/admin/task/task/withdraw', method: 'post', data})
}

export const exportTaskList = (data) => {
  return fetchFile({
    url: '/admin/task/task/exportIndex',
    method: 'post',
    data,
    download: true,
  })
}

export const fetchTaskRecords = (data) => {
  return api({url:'/admin/task/task/getRecords', method: 'post', data})
}

export const recoverTask = (data) => {
  return api({url:'/admin/task/task/putBack', method: 'post', data})
}

export const deleteTaskForever = (data) => {
  return api({url:'/admin/task/task/del', method: 'post', data})
}

export const deleteTaskTemporary = (data) => {
  return api({url:'/admin/task/task/softDelete', method: 'post', data})
}